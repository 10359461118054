import { Box, Typography } from '@mui/material';
import './App.css';


function App() {
  return <Box className='center' display="flex"
    justifyContent="center"
    alignItems="center">
    <Typography variant='h2'>Hi Alice, super soz this was supposed to be ready for xmas but clearly it is not. Will be ready ASAP xxx</Typography>
  </Box>
}

export default App;
